import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import {theme} from './Theme'
import Router from './routes';
import Library from './views/library/Library';


function App() {
  return (
    
    <div >
      
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
       
        
        <Router /> 
        
        
        </StyledEngineProvider>
      </ThemeProvider>
      
    </div>
  );
  
}

export default App;
