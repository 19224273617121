import React from 'react';

const Star = ({ selected, onClick }) =>{
    const starStyle = {
        fontSize: '2.5rem',
        cursor: 'pointer',
        color: selected ? 'gold' : '#ccc',
        transition: 'color 0.2s',
       
      
      };
    
      return (
        <span style={starStyle} onClick={onClick}>
          ★
        </span>
      );
    };

export default Star;
