import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function Snack({ open, handleClose, message, severity }) {
  const action = (
    <React.Fragment>
      <button onClick={handleClose}>x</button>
    </React.Fragment>
  );
  return (
    <div>
     
      <Snackbar 
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        action={action}
      >
        <Alert severity={severity} >
          {message}
        </Alert>
      </Snackbar>
  
    </div>
  );
}
