import React, { useState } from 'react';
import Star from './Star';
import { useEffect } from 'react';

const RatingInput = ({data, readOnly}) => {
  const [rating, setRating] = useState(data);

  const handleStarClick = (selectedRating) => {
    if(readOnly== true){
          setRating(selectedRating);
    }

  };

  // useEffect(() => {
  //   console.log('Rating of filled stars:', rating);
  // }, [rating]);

  return (
    <div className="rating-input">
      {[1, 2, 3, 4, 5].map((number) => (
        <Star

          key={number}
          selected={number <= rating}
          onClick={() => handleStarClick(number)}
        />
      ))}
    </div>
  );
};

export default RatingInput;
