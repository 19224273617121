import React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const Schedule = ({ onDateChange }) => {
  const theme = createTheme({
    palette: {
      type: "dark", // Use dark theme
      primary: {
        main: "#000", // Black color
      },
      background: {
        paper: "#000", // Black background
      },
    },
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: "#fff !important", // Override date color
          },
          daySelected: {
            color: "#fff !important", // Override current date color
            border: "1px solid red !important", // Override border color of the current date (changed to red)
            backgroundColor: "#3B415B !important", // Override background color of the current date's container
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          switchHeader: {
            color: "#fff !important", // Override header color
          },
        },
      },
    },
  });

  const handleDateChange = (newDate) => {
    console.log("date", newDate);
    onDateChange(newDate);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DateCalendar"]}
          sx={{
            color: "white",
            border: "1px solid white",
            borderRadius: "16px",
            mt: "20px",
            mb: "23px",
            bgcolor: "#3B415B",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DateCalendar
            referenceDate={dayjs()}
            views={["year", "month", "day"]}
            onChange={handleDateChange}
            sx={{
              color: "#fff",
              "& .MuiSvgIcon-root": {
                color: "#fff",
              },
              "& .MuiPickersCalendarBody-labelContainer": {
                color: "#fff",
              },
              "& .MuiTypography-root, & .MuiTypography-body1": {
                color: "white",
              },
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& .MuiPickersCalendarHeader-transitionContainer": {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              },
              "& .MuiPickersCalendarHeader-switchHeader": {
                margin: "0 auto",
              },
              "& .MuiPickersCalendarHeader-root": { color: "#fff" },
              "& .MuiPickersDay-root": { color: "#fff" },
              "& .MuiPickersDay-today": {
                borderColor: "red", // Customize this line

                borderBottom: "2px solid red !important",
                borderRight: "0px !important",
                borderTop: " 0px !important",
                borderLeft: "0px !important",
                borderRadius: "0px !important",
                // borderWidth: "2px",
                // borderStyle: "solid",
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Schedule;
