import {
    IconButton,
    TextField,
    InputAdornment,
    Box
  } from "@mui/material";
  import SearchIcon from "@mui/icons-material/Search";

export default function Searchbar(props) {
  return (
    <Box sx={{textAlign:'center',mb:"10px"}}>
    <TextField
    fullWidth
    onChange={props.onChange}
    placeholder="Click here to search"
    className={props.search}
    // sx={{
    //     width:"50%",
    //   mr: 2,
    //   [`& fieldset`]: {
    //     borderRadius: 5,
    //   },
    // }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconButton >
            <SearchIcon  sx={{color:'#fff'}}/>
          </IconButton>
        </InputAdornment>
      ),
    //   classes: {
    //     input: classes.input, // Apply the custom icon class to the input element
    //   },
    }}
  />
  </Box>
  )

}
