import axios from "axios";


export const userUpdate = ({data}) => async (dispatch)=> {
  console.log('+++++++in action',data)
  dispatch({
    type: 'userUpdate',
    payload: data
  });
}

export const login = ({email, password}) => async (dispatch)=> {
    const config = {
        headers: {
          'Content-type': 'Application/json'
        }
      };
    //   const body = JSON.stringify({
    //     email, 
    //     password,
    
    // })
   const body = {
    email,
    password
   }

    try {
        const data = await axios.post(`${process.env.REACT_APP_URL}users/login`, body)
        console.log(data)
        // if (data.status === 200)
        // {
          dispatch({
            type: 'LOGIN_SUCCESS',
            payload: data.data
          });
          return {
            data
          }
        // }
        // else{
        //   return data
        // }

        
    }
    catch(err) {
        return {
            err
         }
    }
}

export const logout = () => (dispatch) =>
  dispatch({
    type: 'LOGOUT_SUUCCESS'
  });