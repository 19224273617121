import { useLocation, useRoutes } from "react-router-dom/dist";
import Dashboard from "./layouts/Dashboard/Dashboard";
import Users from "./views/users/Users";
import Contacts from "./views/contacts/Contacts";
import Crm from "./views/crm/Crm";
import { Navigate } from "react-router-dom/dist";
import CreateUser from "./views/users/components/CreateUser";
import ContactDetails from "./views/contacts/components/ContactDetails";
import CompanyCrm from "./views/crm/components/CompanyCrm";
import Auth from "./layouts/Auth/Auth";
import Login from "./views/auth/Login";
import SignUp from "./views/auth/Signup";
import ForgotPassword from "./views/auth/ForgotPassword";
import UpdateUser from "./views/updateUser/UpdateUser";
import Scheduling from "./views/crm/components/Scheduling";
import UpdateDetails from "./views/contacts/components/UpdateDetails";
import CreateContact from "./views/contacts/components/CreateContact";
import Products from "./views/products/Products";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import { useSelector } from "react-redux";
import CreateProduct from "./views/products/components/CreateProduct";
import UpdateProduct from "./views/products/components/UpdateProduct";
import Companies from "./views/companies/Companies";
import CreateCompany from "./views/companies/components/CreateCompany";
import Contracts from "./views/contracts/Contracts";
import Vendors from "./views/vendors/Vendors";
import CreateVendor from "./views/vendors/components/CreateVendor";
import CreateContracts from "./views/contracts/components/CreateContract";
import UpdateVendor from "./views/vendors/components/UpdateVendor";
import UpdateContract from "./views/contracts/components/UpdateContract";
import Library from "./views/library/Library";
import UploadFile from "./views/library/components/UploadFile";
import CreateInvoces from "./views/invoices/components/createInvoces";
import Gpt from "./views/gpt/components/Gpt";
// import Practice from './views/library/Practice';
import Files from "./views/library/components/Files";
import Images from "./views/library/components/Images";
import Videos from "./views/library/components/Videos";
import Sheets from "./views/sheets/Sheets";
import AddSheet from "./views/sheets/components/AddSheet";
import Dash from "./views/MainDash/Dash";
import Retainer from "./views/sheets/Retainer";
import Invoice from "./views/invoices/components/Invoice";
import { useEffect, useState } from "react";
import CreateInvoice2 from  "./views/CreateInvoice/components/CreateInvoice2";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Profile from "./views/Profile/Profile";

const loaderStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

const contentStyles = {
  background: "#fff",
  padding: "20px",
  borderRadius: "4px",
};
const Loader = () => (
  <div className="loader-container">
    {alert("loading")}
    <div className="loader"> loading.................</div>
  </div>
);
export default function Router() {
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // useLocation to track route changes

  useEffect(() => {
    // Start the loader when the route changes
    setLoading(true);

    // Simulate an API call or other async task
    setTimeout(() => {
      // Stop the loader after the task is complete
      setLoading(false);
    }, 3000); // Replace with your actual data fetching logic
  }, [location]); // Add location to the dependency array

  console.log(loading);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // console.log(isAuthenticated)
  let element = useRoutes([
    {
      element: <ProtectedRoutes isLogged={isAuthenticated} />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
          children: [
            { path: "/dashboard", element: <Navigate to="/dash" replace /> },
            { path: "users", element: <Users /> },
            { path: "new-user", element: <CreateUser /> },
            { path: "update-user/:id", element: <UpdateUser /> },
            { path: "contact-detail", element: <ContactDetails /> },
            { path: "contacts", element: <Contacts /> },
            { path: "new-contact", element: <CreateContact /> },
            // `/dashboard/update-contact/${id}`
            { path: "update-contact/:id", element: <UpdateDetails /> },
            { path: "crm", element: <Crm /> },
            { path: "company-crm", element: <CompanyCrm /> },
            { path: "scheduling", element: <Scheduling /> },
            { path: "products", element: <Products /> },
            { path: "new-product", element: <CreateProduct /> },
            { path: "update-product", element: <UpdateProduct /> },
            { path: "companies", element: <Companies /> },
            { path: "update-company", element: <CreateCompany /> },

            { path: "invoices", element: <Invoice /> },
            { path: "Create-Invoice2", element: <CreateInvoice2/> },
            // { path: "new-invoices", element: <CreateInvoces /> },
            { path: "vendors", element: <Vendors /> },
            { path: "new-vendor", element: <CreateVendor /> },
            { path: "update-vendor", element: <UpdateVendor /> },

            

            { path: "contracts", element: <Contracts /> },
            { path: "new-contract", element: <CreateContracts /> },
            { path: "update-contract", element: <UpdateContract /> },

            { path: "library", element: <Library /> },

            { path: "upload-file", element: <UploadFile /> },
            { path: "library/files", element: <Files /> },
            { path: "library/images", element: <Images /> },
            { path: "library/videos", element: <Videos /> },

            { path: "gpt", element: <Gpt /> },

            { path: "retainer", element: <Retainer /> },
            { path: "_retainer", element: <Sheets /> },
            { path: "new-retainer", element: <AddSheet /> },

            { path: "dash", element: <Dash /> },
            { path: "profile", element: <Profile /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <Auth />,
      children: [
        { path: "/", element: <Navigate to="/login" replace /> },
        { path: "login", element: <Login /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "sign-up", element: <SignUp /> },
      ],
    },
  ]);

  return (
    <div style={{ background: "#CCCCCC", height: "100%" }}>
      {loading && (
        <div style={loaderStyles}>
          <div >
            {/* Add your loader content or spinner here */}
            <Box
              style={{
                // background:'#fff',
                padding: "10px",
                width: "130px",
                height: "130px",
                borderRadius: "200px",
                border: "1px solid",
                background: "#000",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src="/assets/images/logo1.png" style={{ width: "70px",animation: "bounce 1s infinite",}} />
            </Box>
          </div>
        </div>
      )}
      <div>{element}</div>
    </div>
  );
}
