import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const Overview = (props) => {
  const [chartOptions, setChartOptions] = useState({
    title: {
      // text: 'Column Charts',
      style: {

        color: 'white',
        fontWeight: 'bold',
      },
    },
    plotOptions: {
        
    },
    theme: {
      // mode: 'light',
    },
    fill: {
      type: 'solid',
      colors: ['#3D8FD6'], // Change this to your desired color
    },
    stroke: {
      curve: 'smooth',
     
    },
    chart: {
      type: "area",
      stacked: true,
      animations: {
        enabled: true,
      },
      stacked: true,
    
      background: '#187ACE',
    },
    colors: ['#fff'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: props.graph.labels || [],
      labels: {
        style: {
          fontWeight: 'bolder',
          colors: 'white',
        },
      }
    },
    yaxis: {
      title: {},
      labels: {
        formatter: function (value) {
          return "$" + value.toFixed(0);
        },
        style: {
          fontWeight: 'bolder',
          colors: 'white', 
        },
      },
    },
  });


  
  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: props.graph.labels || [],
      },
    }));
  }, [props.graph.labels]);

  const series = [
    {
      name: props.graph.labels,
      data: props.graph.data,
    },
  ];

  return (
    <div>
      <ReactApexChart options={chartOptions} series={series} type="area" height={300} />
    </div>
  );
};

export default Overview;
