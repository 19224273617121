import React from "react";
import "../../App.css";
import { Outlet } from "react-router";
import { Box } from "@mui/material";
const Auth = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#F4F4F4",
        padding: 0,
        margin: 0,
      }}
    >
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Auth;
